import { FormControl, FormErrorMessage } from "@chakra-ui/react";
import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { isLoading, openAssetInNewTab } from "../../common/helper/commonHelper";
import {
  selectWebhook,
  listAllWebhooks,
} from "../../pages/dashboard/webhook/webhookSlice";
import { useAppDispatch } from "../../store";
import DropdownWithSearch from "../DropdownWithSearch";
import { AssetPreviewComponent, WebhookDropdownOption } from "../OptionHelper";
import IFormLabel from "../IFormLabel";
import {
  listStaticListSummaryList,
  selectStaticList,
} from "../../pages/dashboard/staticlist/staticlistSlice";
import { ASSET_TYPES } from "../../common/constants/common";
import { ReactSelectDefaultOptionsWithClick } from "../../common/types/common";

export function WebhooksDropdown({
  webhookId,
  onSelect,
  label,
  isInvalid,
  isReadOnly,
}: {
  webhookId: string | null;
  onSelect: (id: string | null) => void;
  label?: string;
  isInvalid?: boolean;
  isReadOnly?: boolean;
}) {
  const { allWebhooksList } = useSelector(selectWebhook);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(listAllWebhooks());
  }, [dispatch]);

  return (
    <FormControl isInvalid={isInvalid}>
      {label && <IFormLabel>{label}</IFormLabel>}
      <DropdownWithSearch
        options={allWebhooksList.data}
        placeholder="Select webhook"
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.webhook_id}
        value={
          webhookId
            ? allWebhooksList.data.find(
                (option) => option.webhook_id === webhookId
              )
            : null
        }
        onChange={(option) => onSelect(option?.webhook_id ?? null)}
        isInvalid={isInvalid}
        isLoading={isLoading(allWebhooksList.loading)}
        maxMenuHeight={300}
        isDisabled={isReadOnly}
        isSearchable
        components={{ Option: WebhookDropdownOption }}
      />
      <FormErrorMessage>Webhook is required</FormErrorMessage>
    </FormControl>
  );
}

export function StaticListsDropdown({
  staticListId,
  onSelect,
  label,
  isInvalid,
  isReadOnly,
}: {
  staticListId: string | null;
  onSelect: (id: string | null) => void;
  label?: string;
  isInvalid?: boolean;
  isReadOnly?: boolean;
}) {
  const { staticListSummaryList } = useSelector(selectStaticList);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(listStaticListSummaryList());
  }, [dispatch]);

  const staticLists: ReactSelectDefaultOptionsWithClick[] = useMemo(
    () =>
      staticListSummaryList.data.map((staticList) => {
        return {
          label: staticList.name,
          value: staticList.static_list_id,
          onPreview: () =>
            openAssetInNewTab(
              ASSET_TYPES.STATIC_LIST,
              staticList.static_list_id
            ),
        };
      }),
    [staticListSummaryList.data]
  );

  return (
    <FormControl isInvalid={isInvalid}>
      {label && <IFormLabel>{label}</IFormLabel>}
      <DropdownWithSearch
        options={staticLists}
        placeholder="Select static list"
        value={
          staticListId
            ? staticLists.find((option) => option.value === staticListId)
            : null
        }
        onChange={(option) => onSelect(option?.value ?? null)}
        isInvalid={isInvalid}
        isLoading={isLoading(staticListSummaryList.loading)}
        maxMenuHeight={300}
        isDisabled={isReadOnly}
        isSearchable
        components={{
          Option: AssetPreviewComponent<ReactSelectDefaultOptionsWithClick>,
        }}
      />
      <FormErrorMessage>Static list is required</FormErrorMessage>
    </FormControl>
  );
}
