import { debounce, isEmpty } from "lodash";
import { useCallback, useEffect, useMemo, useState, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  SEARCH_QUERY_PARAM,
  PAGE_NUM_PARAM,
  FILTER_PARAM,
} from "../constants/common";
import {
  formatLastSaved,
  isBlank,
  safeParseJSON,
} from "../helper/commonHelper";
import { ListFilterItem, PaginationFilterParams } from "../types/common";
import { useTheme } from "@chakra-ui/react";
import { PREV_PATH } from "../constants";
import { useAppDispatch } from "../../store";
import { getContactIngestionEtlService } from "../slices/featureFlagSlice";

export function useUrlSearchParams() {
  const [searchParams, setSearchParams] = useSearchParams();

  // Get a parameter as a string
  const getStringParam = (paramKey: string): string | null => {
    return searchParams.get(paramKey);
  };

  // Get a parameter as a number
  const getNumberParam = (paramKey: string): number | null => {
    const value = searchParams.get(paramKey);
    return value !== null ? Number(value) : null;
  };

  // Get a parameter as an object (assumes JSON string)
  const getObjectParam = <T>(paramKey: string): T | null => {
    const value = searchParams.get(paramKey);
    return value !== null ? safeParseJSON<T>(value) : null;
  };

  // Set multiple parameters at once
  const setParams = (params: {
    [paramKey: string]: string | number | object | null | undefined;
  }): void => {
    Object.entries(params).forEach(([paramKey, value]) => {
      if (isBlank(value)) {
        searchParams.delete(paramKey); // Remove the parameter if the value is null
      } else {
        if (typeof value === "object") {
          searchParams.set(paramKey, JSON.stringify(value));
        } else {
          searchParams.set(paramKey, value.toString());
        }
      }
    });
    setSearchParams(searchParams);
  };

  return {
    getStringParam,
    getNumberParam,
    getObjectParam,
    setParams,
  };
}

const TIME_UNIT = 20000;

export function useLastSavedStatus(updatedAt: string | number) {
  const [lastSaved, setLastSaved] = useState("");

  const updateLastSavedStatus = useCallback((updatedAt: string | number) => {
    if (updatedAt) {
      setLastSaved(formatLastSaved({ updatedAt, includeSeconds: true }));
    }
  }, []);

  useEffect(() => {
    updateLastSavedStatus(updatedAt);
    const intervalId = setInterval(
      () => updateLastSavedStatus(updatedAt),
      TIME_UNIT
    );
    return () => clearInterval(intervalId);
  }, [updatedAt, updateLastSavedStatus]);

  return [lastSaved];
}

export function useDebouncedSearch({
  searchIfValid,
  delay = 1000,
}: {
  searchIfValid: (searchKeyword: string) => void;
  delay?: number;
}) {
  return useMemo(() => debounce(searchIfValid, delay), [searchIfValid, delay]);
}

export function useUpdateWithInitValue<T>(data: T) {
  const [currentState, setCurrentState] = useState(data);

  useEffect(() => {
    setCurrentState(data);
  }, [data]);

  return [currentState, setCurrentState] as [
    T,
    React.Dispatch<React.SetStateAction<T>>
  ];
}

export function useHover(): [
  boolean,
  { onMouseEnter(): void; onMouseLeave(): void }
] {
  const [hovered, setHovered] = useState(false);

  const eventHandlers = useMemo(
    () => ({
      onMouseEnter() {
        setHovered(true);
      },
      onMouseLeave() {
        setHovered(false);
      },
    }),
    []
  );

  return [hovered, eventHandlers];
}

export function usePreventRefresh({
  preventRefresh,
  warningMessage,
}: {
  preventRefresh: boolean;
  warningMessage?: string;
}) {
  useEffect(() => {
    function handleBeforeUnload(event: BeforeUnloadEvent) {
      event.preventDefault();

      // INFO: custom messages won't work in latest browsers
      // https://stackoverflow.com/questions/38879742/is-it-possible-to-display-a-custom-message-in-the-beforeunload-popup

      const warningMsg = warningMessage ? warningMessage : "Discard changes ?";
      event.returnValue = warningMsg;
      return warningMsg;
    }

    if (preventRefresh) {
      window.addEventListener("beforeunload", handleBeforeUnload);
    } else {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    }

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [preventRefresh, warningMessage]);
}

export function useExponentialPolling({
  initialWaitTime = 2,
  exponent = 2,
  maxPollingSeconds = 16,
  shouldPoll,
  onPolling,
}: {
  initialWaitTime?: number;
  onPolling: () => void;
  shouldPoll: boolean;
  exponent?: number;
  maxPollingSeconds?: number;
}) {
  const timeoutIds = useRef<NodeJS.Timeout[]>([]);

  const exponentialPolling = useCallback(
    (waitTime: number) => {
      if (!shouldPoll) {
        return;
      }

      const timeoutId = setTimeout(() => {
        onPolling();

        const nextWaitTime = waitTime * exponent;
        if (nextWaitTime === 0) {
          exponentialPolling(exponent);
        } else if (nextWaitTime <= maxPollingSeconds) {
          exponentialPolling(nextWaitTime);
        } else {
          exponentialPolling(maxPollingSeconds);
        }
      }, waitTime * 1000);
      timeoutIds.current.push(timeoutId);
    },

    [exponent, maxPollingSeconds, onPolling, shouldPoll]
  );

  useEffect(() => {
    if (shouldPoll) {
      exponentialPolling(initialWaitTime);
    }
    return () => {
      timeoutIds.current.forEach(clearTimeout);
      timeoutIds.current = [];
    };

    // should run only once , to start polling when initial time changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialWaitTime, shouldPoll]);
}

function getUndefinedIfEmpty(filters: ListFilterItem | undefined) {
  return isEmpty(filters) ? undefined : filters;
}

export const usePaginatedData = ({
  fetchList,
  fetchingList,
}: {
  fetchList: (data: PaginationFilterParams) => void;
  fetchingList?: boolean;
}) => {
  const [isInit, setIsInit] = useState(true);

  const { getStringParam, getNumberParam, getObjectParam, setParams } =
    useUrlSearchParams();

  const searchKeyword = getStringParam(SEARCH_QUERY_PARAM) ?? "";
  const pageNo = getNumberParam(PAGE_NUM_PARAM) ?? 1;
  const filters = getObjectParam<ListFilterItem>(FILTER_PARAM) ?? {};

  useEffect(() => {
    fetchList({
      pageNo: pageNo,
      searchKeyword,
      filters: getUndefinedIfEmpty(filters),
    });
    setIsInit(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageChange = (page: number) => {
    setParams({
      [PAGE_NUM_PARAM]: page,
    });
    fetchList({
      pageNo: page,
      searchKeyword,
      filters: getUndefinedIfEmpty(filters),
    });
  };

  function searchIfValid(query: string) {
    setParams({
      [PAGE_NUM_PARAM]: 1,
      [SEARCH_QUERY_PARAM]: query,
    });
    fetchList({
      pageNo: 1,
      searchKeyword: query,
      filters: getUndefinedIfEmpty(filters),
    });
  }

  const debouncedSearch = useDebouncedSearch({ searchIfValid });

  const handleSearchChange = (keyword: string) => {
    debouncedSearch(keyword);
  };

  const handleFilterChange = (filters: ListFilterItem) => {
    fetchList({
      pageNo: 1,
      searchKeyword,
      filters: getUndefinedIfEmpty(filters),
    });
    setParams({
      [PAGE_NUM_PARAM]: 1,
      [FILTER_PARAM]: getUndefinedIfEmpty(filters),
    });
  };

  const isFiltersApplied = searchKeyword || !isEmpty(filters);

  return {
    fetchingList: !!fetchingList || isInit,
    isFiltersApplied,
    searchKeyword,
    filters,
    handlePageChange,
    handleSearchChange,
    handleFilterChange,
  };
};

export function useChakraColors() {
  const theme = useTheme();
  return {
    //red
    red500: theme.__cssVars["--chakra-colors-red-500"],
    //blue
    blue100: theme.__cssVars["--chakra-colors-blue-100"],
    blue200: theme.__cssVars["--chakra-colors-blue-200"],
    blue500: theme.__cssVars["--chakra-colors-blue-500"],
    blue600: theme.__cssVars["--chakra-colors-blue-600"],

    //gray
    gray100: theme.__cssVars["--chakra-colors-gray-100"],
    gray200: theme.__cssVars["--chakra-colors-gray-200"],
    gray300: theme.__cssVars["--chakra-colors-gray-300"],
    gray400: theme.__cssVars["--chakra-colors-gray-400"],
    gray500: theme.__cssVars["--chakra-colors-gray-500"],
    gray600: theme.__cssVars["--chakra-colors-gray-600"],
    gray800: theme.__cssVars["--chakra-colors-gray-800"],

    //text
    text200: theme.__cssVars["--chakra-colors-text-200"],
    text50: theme.__cssVars["--chakra-colors-text-50"],

    //basic
    title: theme.__cssVars["--chakra-colors-basic-title"],
  };
}

export function usePreviousLocation() {
  const prevPath = localStorage.getItem(PREV_PATH);
  const navigate = useNavigate();
  function handleGoBackWithParams(url?: string) {
    if (url && prevPath !== url) {
      navigate(url);
    } else if (window.history.length > 1) {
      navigate(-1); // Default back behavior
    } else if (url) {
      navigate(url); // Fallback to URL navigation if no browser history (new tab)
    }
  }
  return {
    handleGoBackWithParams,
  };
}

export function useNumberIterate(initStep: number | null = null) {
  const [stepNum, setStepNum] = useState(initStep);

  function isCurrentStep(step: number) {
    return stepNum === step;
  }

  function goToPrevStep() {
    setStepNum((prev) => (prev !== null ? --prev : null));
  }

  function goToNextStep() {
    setStepNum((prev) => (prev !== null ? ++prev : 0));
  }

  function jumpToStep(step: number | null) {
    setStepNum(step);
  }

  return {
    currentStep: stepNum,
    isCurrentStep,
    goToNextStep,
    goToPrevStep,
    jumpToStep,
  };
}

export function useContactIngestionApiRetry() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getContactIngestionEtlService());
  }, [dispatch]);
}
