import { isEmpty } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  clearCampaignDetails,
  selectCampaign,
  publishCampaign,
  saveCampaignDraft,
  setPublishLoadState,
  publishCampaignDraft,
  setCampaignDetails,
  getCampaign,
} from "./campaignSlice";
import { toast } from "react-toastify";
import { CAMPAIGN_STATUS } from "../../../common/constants/campaign";
import {
  CampaignValidationType,
  CAMPAIGN_CONTEXT,
  DraftPublishType,
  DraftSendDataType,
} from "../../../common/types/campaign";
import { formatScheduleData } from "./helper/formatHelper";
import {
  isFailed,
  isFulfilled,
  isLoading,
} from "../../../common/helper/commonHelper";
import { useAppDispatch } from "../../../store";
import { publishDraftApi } from "../../../common/api/campaign/campaign";
import { useNavigate, useParams } from "react-router-dom";
import urls from "../../../urls";
import BatchCampaignBuilder from "./BatchCampaignBuilder";
import { getCampaignFlow, selectFlow, setFlow } from "./flow/flowSlice";
import { JOURNEY_TYPES } from "../../../common/constants/trigger";

export default function BatchCampaign() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  let { id } = useParams<{ id: string }>();

  // Support for old urls | Replacing old urls to new
  useEffect(() => {
    if (id) {
      navigate(urls.batchJourneyEdit.replace(":id", id), { replace: true });
    }
  }, [navigate, id]);

  useEffect(() => {
    if (id) {
      dispatch(getCampaign(id));
      dispatch(getCampaignFlow(id));
    }
  }, [id, dispatch]);

  const {
    campaignDetails: {
      data: campaignDetails,
      loading: isCampaignDetailsLoading,
    },
    publishingCampaign,
    savingDraft,
    campaignTags,
    activeErrorCheck,
  } = useSelector(selectCampaign);

  const { isEditingFlow } = useSelector(selectFlow);

  useEffect(() => {
    if (isFailed(isCampaignDetailsLoading)) {
      navigate(urls.journey);
    }
  }, [navigate, isCampaignDetailsLoading]);

  const [validity, setValidity] = useState<CampaignValidationType>({
    dynamicList: "",
    flow: "",
    schedule: "",
    rule: "",
    exitCriteria: "",
  });

  const initDynamicList = useMemo(() => {
    const dynamicList =
      campaignDetails.draft_data?.dynamic_list ?? campaignDetails.dynamic_list;
    return dynamicList?.query.conditions || [];
  }, [campaignDetails.draft_data?.dynamic_list, campaignDetails.dynamic_list]);

  const initSchedule = useMemo(() => {
    return formatScheduleData(
      campaignDetails.draft_data?.schedule ?? campaignDetails.schedule,
      campaignDetails.campaign_id
    );
  }, [
    campaignDetails.draft_data?.schedule,
    campaignDetails?.schedule,
    campaignDetails.campaign_id,
  ]);

  const initRule = useMemo(() => {
    return campaignDetails.draft_data.rule ?? campaignDetails.rule;
  }, [campaignDetails.draft_data.rule, campaignDetails.rule]);

  const initExitCriteria = useMemo(() => {
    const exitCriteria =
      campaignDetails.draft_data?.exit_criteria?.conditions ??
      campaignDetails.exit_criteria?.conditions;
    return exitCriteria ?? null;
  }, [
    campaignDetails.draft_data?.exit_criteria?.conditions,
    campaignDetails.exit_criteria?.conditions,
  ]);

  useEffect(() => {
    return () => {
      dispatch(clearCampaignDetails());
    };
  }, [dispatch]);

  const saveDraft = useCallback(
    (current: DraftSendDataType) => {
      if (!campaignDetails.active && !publishingCampaign) {
        dispatch(saveCampaignDraft(current));
      }
    },
    [campaignDetails.active, publishingCampaign, dispatch]
  );

  const publishIfValid = useCallback(
    async (details: DraftPublishType, active: boolean) => {
      if (!details.error) {
        await dispatch(publishCampaign({ active }));
      } else {
        const errors = details.error_details;
        const { dynamic_list, flow, schedule, rule, exit_criteria } = errors;
        if (!isEmpty(errors)) {
          setValidity({
            dynamicList: dynamic_list,
            flow,
            schedule,
            rule,
            exitCriteria: exit_criteria,
          });
          const errorMsg = Object.entries(errors)
            .map(([key, value]) => `${key}: ${value}`)
            .join(", ");
          toast.error(errorMsg, { autoClose: false });
        }
      }
    },
    [dispatch]
  );

  const status = useMemo(() => {
    return campaignDetails.active
      ? CAMPAIGN_STATUS.ACTIVE
      : campaignDetails.draft
      ? CAMPAIGN_STATUS.DRAFT
      : CAMPAIGN_STATUS.INACTIVE;
  }, [campaignDetails.active, campaignDetails.draft]);

  async function publishData(active: boolean) {
    if (status === CAMPAIGN_STATUS.ACTIVE) {
      await dispatch(publishCampaign({ active: false }));
    } else {
      const publishDraftResult = await dispatch(publishCampaignDraft());
      if (isFulfilled(publishDraftResult.meta.requestStatus)) {
        const newCampaignDetails = publishDraftResult.payload as Awaited<
          ReturnType<typeof publishDraftApi>
        >;
        if (!newCampaignDetails.error) {
          dispatch(setCampaignDetails(newCampaignDetails.campaign));
          dispatch(setFlow(newCampaignDetails.campaign.flow));
          await publishIfValid(newCampaignDetails, active);
        } else {
          toast.error(
            Object.values(newCampaignDetails.error_details)[0] ??
              "Publish failed!"
          );
        }
      }
    }
  }

  async function saveAndPublishData(
    current: DraftSendDataType,
    active: boolean
  ) {
    dispatch(setPublishLoadState(true));
    // If campaign is in draft state, then we have to save the draft details one more final time
    // to not miss any data in between debounce interval.
    try {
      if (status === CAMPAIGN_STATUS.DRAFT) {
        const saveResult = await dispatch(saveCampaignDraft(current));
        if (isFulfilled(saveResult.meta.requestStatus)) {
          await publishData(active);
        }
      } else {
        await publishData(active);
      }
      dispatch(setPublishLoadState(false));
    } catch (errors) {
      toast.error("Journey publish failed");
      dispatch(setPublishLoadState(false));
    }
  }

  // Handling last updated at since flow and campaign is at different places.
  const lastUpdatedAt = useMemo(() => {
    const draftUpdatedAt = campaignDetails.draft_data?.updated_at;
    const createdAt = campaignDetails.updated_at;
    return draftUpdatedAt ? draftUpdatedAt : createdAt;
  }, [campaignDetails.draft_data?.updated_at, campaignDetails.updated_at]);

  // Handling last updated by since flow and campaign is at different places.
  const lastUpdatedId = useMemo(() => {
    const draftUpdatedId = campaignDetails.draft_data?.updated_by?.id;
    const createdId = campaignDetails.created_by.id;
    return draftUpdatedId ? String(draftUpdatedId) : String(createdId);
  }, [
    campaignDetails.draft_data?.updated_by?.id,
    campaignDetails.created_by.id,
  ]);

  return (
    <BatchCampaignBuilder
      campaignDetails={{
        campaignName: campaignDetails.name,
        campaignId: campaignDetails.campaign_id,
        campaignContext:
          campaignDetails.campaign_context ?? CAMPAIGN_CONTEXT.PERSON,
        status: status,
        isActivatedOnce: !!campaignDetails.activated_at,
        updatedBy: lastUpdatedId,
        isSavingDraft: savingDraft || isEditingFlow,
        isPublishingCampaign: publishingCampaign,
        campaignTags: campaignTags,
        updatedAt: lastUpdatedAt,
        fetchingDetails: isLoading(isCampaignDetailsLoading),
        activeErrorCheck: activeErrorCheck,
        campaignType: JOURNEY_TYPES.BATCH,
      }}
      initCampaignData={{
        dynamicList: initDynamicList,
        schedule: initSchedule,
        rule: initRule,
        exitCriteria: initExitCriteria,
        validity: validity,
      }}
      saveDraft={saveDraft}
      publishData={saveAndPublishData}
    />
  );
}
