import { createAsyncThunk, Dispatch } from "@reduxjs/toolkit";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import {
  capitalize,
  cloneDeep,
  isFinite,
  isNumber,
  isString,
  set,
  startCase,
  isEmpty,
  snakeCase,
  floor,
} from "lodash";
import { LinkProps, useLocation } from "react-router-dom";
import { RootState } from "../../store";
import urls from "../../urls";
import { MANY_ARGUMENTS } from "../constants/campaign";
import {
  LOADING_STATES,
  PRODUCTION_HOST,
  STAGING_HOST,
  INITIAL_PAGINATION,
  EMPTY_CONTEXT,
  ALL_TIMEZONES_WITH_ABBREVIATION,
  ASSET_TYPES,
  ASSET_TYPES_INFO,
  CUSTOM_DATE_RANGE_LABELS,
  getStartDate,
  TRANSACTION_STATUS,
} from "../constants/common";
import {
  EMAIL_CONFIG_ERRORS_INIT,
  EMAIL_CONFIG_FIELDS,
  EMAIL_INPUTS_TO_VALIDATE,
  VALUE_META_TYPE,
} from "../constants/template";
import { setApiStatus } from "../slices/apiStatusSlice";
import { Auth, UserListMap, USER_ACCOUNT_STATUS } from "../types/auth";
import {
  CAMPAIGN_CONTEXT,
  EmailTokenColumn,
  EmailTokenDetails,
  GlobalEmailConfigType,
  OperatorList,
  OperatorType,
} from "../types/campaign";
import {
  InfiniteListType,
  LoadingWithData,
  PaginationTypeWithLoadingState,
} from "../types/common";
import { EVENT_TYPE } from "../types/connection";
import {
  PersonDestination,
  PersonDestinationFields,
  ProductPersonType,
} from "../types/person";
import {
  EmailConfigErrors,
  EmailConfigWithSubject,
  SenderMeta,
} from "../types/template";
import { DESTINATION_TYPES } from "../types/unifiedMapping";
import hash from "object-hash";
import { format, isSameDay } from "date-fns";
import { TableRowProps } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { TenantDetails } from "../types/settings";
import { UPLOAD_CONTACTS_TO } from "../types/contactUpload";
import { EXPORT_STATUS } from "../types/export";
import { TOKEN_RETURN_TYPE } from "../constants/token";
import { hasDuplicateRecipientInLists } from "./templateHelper";
import { CONTACTS_INGESTION_SERVICE } from "../types/featureFlag";

export const convertCase = (data: string) => {
  return startCase(data);
};

export const copyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text);
};

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const validatePassword = (password: string) => {
  return password.length >= 6;
};

export const validateEmail = (email: string) => {
  var pattern = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

  return email !== "" && pattern.test(email);
};

export const formatLargeNumber = (num: number, digits: number) => {
  const lookup = [
    { value: 1e18, symbol: "Quinitillion" },
    { value: 1e15, symbol: "Quadillion" },
    { value: 1e12, symbol: "Trillion" },
    { value: 1e9, symbol: "Billion" },
    { value: 1e6, symbol: "Million" },
    { value: 1, symbol: "" },
  ];
  //To check if decimal place <= 0.99
  const floatLessThanOne = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup.find((item) => num >= item.value);
  return item
    ? `${(num / item.value).toFixed(digits).replace(floatLessThanOne, "$1")} ${
        item.symbol
      }`
    : "0";
};

export function isLoading(status?: LOADING_STATES) {
  return status === LOADING_STATES.LOADING;
}

export function isSuccess(status?: LOADING_STATES) {
  return status === LOADING_STATES.SUCCESS;
}

export function isInit(status?: LOADING_STATES) {
  return status === LOADING_STATES.INIT;
}

export function isFailed(status?: LOADING_STATES) {
  return status === LOADING_STATES.FAILED;
}

export function isFinished(status?: LOADING_STATES) {
  return status === LOADING_STATES.FAILED || status === LOADING_STATES.SUCCESS;
}

export function isRejected(checkString: string) {
  return checkString === "rejected";
}

export function isFulfilled(checkString: string) {
  return checkString === "fulfilled";
}

export const formatNumber = (number: number): string => {
  return isFinite(number) ? number.toLocaleString("en-US") : "-";
};

export const convertToString = (
  value: string | number | null | undefined,
  fallback?: string
) => {
  if (isString(value)) {
    return formatNumber(+value);
  } else if (isNumber(value)) {
    return formatNumber(value);
  } else {
    return fallback ?? "";
  }
};

// Returns a synthetic event when passed an event
// Using this now for creating an input onChange event for email restriction component to efficiently use formik functionality
export const createSyntheticEvent = <T extends Element, E extends Event>(
  event: E
): React.SyntheticEvent<T, E> => {
  let isDefaultPrevented = false;
  let isPropagationStopped = false;
  const preventDefault = () => {
    isDefaultPrevented = true;
    event.preventDefault();
  };
  const stopPropagation = () => {
    isPropagationStopped = true;
    event.stopPropagation();
  };
  return {
    nativeEvent: event,
    currentTarget: event.currentTarget as EventTarget & T,
    target: event.target as EventTarget & T,
    bubbles: event.bubbles,
    cancelable: event.cancelable,
    defaultPrevented: event.defaultPrevented,
    eventPhase: event.eventPhase,
    isTrusted: event.isTrusted,
    preventDefault,
    isDefaultPrevented: () => isDefaultPrevented,
    stopPropagation,
    isPropagationStopped: () => isPropagationStopped,
    persist: () => {},
    timeStamp: event.timeStamp,
    type: event.type,
  };
};

export const customOnChangeHandler = (
  field: string,
  newValue: any,
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
) => {
  const target = document.createElement("input");
  target.value = newValue;
  target.name = field;
  const event = new Event("change", { bubbles: true });
  Object.defineProperty(event, "target", { writable: false, value: target });
  const syntheticEvent = createSyntheticEvent(event) as React.ChangeEvent<
    typeof target
  >;
  if (onChange) {
    onChange(syntheticEvent);
  }
  target.remove();
};

type GroupTypes = "outer" | "filter" | "property";

// For dynamic list side bar use
export const createGroupId = (
  parentId: string,
  groupType: GroupTypes,
  index: number
) => {
  return `${parentId}-${groupType}-group-${index}`;
};

export const percentageOf = (
  data: number,
  total: number,
  appendSymbol = false,
  decimalPlaces?: number
) => {
  const percentage = total && data ? (data * 100) / total : 0;
  const percentageFloat = +parseFloat(`${percentage}`).toFixed(
    decimalPlaces ?? 2
  );
  return appendSymbol ? `${percentageFloat}%` : percentageFloat;
};

export const onEnterKeySubmit = (
  e: React.KeyboardEvent<HTMLInputElement>,
  callback: (param?: string) => void
) => {
  if (e.key === "Enter") {
    callback.apply(null, []);
  }
};

export const keepClickEventRestricted = (
  e: React.MouseEvent<
    HTMLButtonElement | HTMLInputElement | HTMLDivElement,
    MouseEvent
  >,
  callback?: (param?: string) => void
) => {
  e.preventDefault();
  e.stopPropagation();
  callback?.apply(null, []);
};

export const textLimitter = (text: string, length: number) => {
  if (text.length > length) return `${text.slice(0, length)}...`;
  return text;
};

export function getShortTimezoneName() {
  return new Date()
    .toLocaleDateString("en", { day: "2-digit", timeZoneName: "short" })
    .slice(4);
}

export function initializeLoadingData(
  initData: any
): LoadingWithData<typeof initData> {
  return {
    data: initData,
    loading: LOADING_STATES.INIT,
  };
}

export function initializeInfiniteList({
  startPageNo,
  pageSize,
}: {
  startPageNo?: number;
  pageSize?: number;
}): InfiniteListType<any> {
  return {
    list: null,
    loadingList: LOADING_STATES.INIT,
    totalPageCount: null,
    currentPageNo: startPageNo ?? 1,
    pageSize: pageSize ?? 10,
    count: 0,
  };
}

export function downloadFile(file: Blob, filename: string) {
  if (file) {
    const url = window.URL.createObjectURL(file);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
  }
}

export function isProduction() {
  return document.location.host === PRODUCTION_HOST;
}
export function isStaging() {
  return document.location.host === STAGING_HOST;
}
export function isUnsubLandingPage() {
  return document.location.pathname === urls.unsubscribed;
}

export function generateRandomString(length: number, prefixText?: string) {
  const ALPHABETS =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let randomString = "";

  for (let i = 0; i < length; i++) {
    randomString += ALPHABETS[Math.floor(Math.random() * ALPHABETS.length)];
  }

  return prefixText ? prefixText + randomString : randomString;
}

export function openSalesforceInstance({
  url,
  crmObject,
  salesforceId,
}: {
  url: string;
  crmObject: string;
  salesforceId: string;
}) {
  if (url && crmObject && salesforceId) {
    window.open(`${url}/lightning/r/${crmObject}/${salesforceId}/view`);
  }
}

export function formatLastSaved({
  updatedAt = "",
  includeSeconds = false,
  addSuffix = true,
  locale,
}: {
  updatedAt: string | number;
  includeSeconds?: boolean;
  addSuffix?: boolean;
  locale?: Locale;
}) {
  return updatedAt
    ? "Last saved " +
        formatDistanceToNow(new Date(updatedAt), {
          addSuffix,
          includeSeconds,
          locale,
        })
    : "";
}

export function isEmptyProtocol(url: string) {
  try {
    const newUrl = new URL(url);
    return !newUrl.protocol;
  } catch (e) {
    return true;
  }
}

export function isValidUrl(url: string | null) {
  if (!url) return false;

  try {
    const newUrl = new URL(url);
    return newUrl.protocol === "https:" || newUrl.protocol === "http:";
  } catch (e) {
    return false;
  }
}

export function isBlank(value: any): value is undefined | null | "" {
  return value === undefined || value === "" || value === null;
}

export function createUserMapping(usersList: Auth[]) {
  let keyedUsers: UserListMap = {};
  keyedUsers = usersList.reduce((map, user) => {
    map[user.id] = user;
    return map;
  }, keyedUsers);
  return keyedUsers;
}

export function findUserFromMapping(id: string, usersListMap: UserListMap) {
  return usersListMap[id]?.name ?? "";
}

export function getUserStatusFromMapping(
  id: string,
  usersListMap: UserListMap
) {
  return usersListMap[id]?.status ?? USER_ACCOUNT_STATUS.PENDING;
}

export function formatDataForDisplay(
  key: string,
  value: any,
  dataType: DESTINATION_TYPES
) {
  return {
    key: key,
    details: {
      type: dataType,
      display: capitalize(key.replaceAll("_", " ")),
      value: value,
      read_only: true,
      required: false,
      length: null,
      name: "",
      custom: false,
      hidden: false,
      inferred: false,
    },
  };
}

export function addSuffixForPlural(text: string, count: number | undefined) {
  return `${text}${count && count > 1 ? "s" : ""}`;
}

export function checkIsAccountInactive(accountStatus: string) {
  return accountStatus === USER_ACCOUNT_STATUS.INACTIVE;
}

export function isTrackEvent(segmentEventName: string) {
  return [EVENT_TYPE.GROUP, EVENT_TYPE.IDENTIFY].includes(segmentEventName);
}

export function getEventTypeAndName(eventName: string) {
  return {
    event_type: isTrackEvent(eventName) ? eventName : EVENT_TYPE.TRACK,
    event_name: isTrackEvent(eventName) ? undefined : eventName,
  };
}

export function sortDetails(
  first: [string, PersonDestination],
  second: [string, PersonDestination]
) {
  const firstReadonly = !!first[1].read_only;
  const secondReadonly = !!second[1].read_only;
  const firstDisplayName = first[1].display ?? "z";
  const secondDisplayName = second[1].display ?? "z";
  if (firstReadonly === secondReadonly) {
    return firstReadonly ? 0 : firstDisplayName > secondDisplayName ? 1 : -1;
  } else {
    return firstReadonly ? -1 : 1;
  }
}

export function sortAlphabetically(first: string, second: string) {
  var textA = first.toUpperCase();
  var textB = second.toUpperCase();
  return textA < textB ? -1 : textA > textB ? 1 : 0;
}

export function convertToProductPersonType(
  filteredEntries: [string, PersonDestination][]
) {
  const newEntries: ProductPersonType[] = [];
  filteredEntries.forEach((value) => {
    newEntries.push({ key: value[0], details: value[1] });
  });
  return newEntries;
}

export function isArgumentMany(argument?: string) {
  return argument === MANY_ARGUMENTS;
}

function checkAndSetErrors(
  value: string,
  key: EMAIL_CONFIG_FIELDS,
  message: string,
  errors: EmailConfigErrors,
  shouldValidate = true,
  email = false
): {
  validity: boolean;
  newErrors: EmailConfigErrors;
} {
  if (shouldValidate) {
    const errorMsg = (value && !email) || validateEmail(value) ? "" : message;

    const newErrors = cloneDeep(errors);

    set(newErrors, key, errorMsg);

    return {
      validity: !errorMsg,
      newErrors,
    };
  }

  //delete the key's value
  set(errors, key, undefined);

  return { validity: true, newErrors: errors };
}

export function validateEmailConfigInputs(
  inputData: EmailConfigWithSubject | (GlobalEmailConfigType & SenderMeta),
  inputsToValidate = EMAIL_INPUTS_TO_VALIDATE
) {
  const errors = EMAIL_CONFIG_ERRORS_INIT;

  const subjectError = checkAndSetErrors(
    "subject" in inputData ? inputData.subject : "",
    EMAIL_CONFIG_FIELDS.SUBJECT,
    "Email subject should not be empty",
    errors,
    inputsToValidate.subject
  );

  const nameError = checkAndSetErrors(
    inputData.from_email.name,
    EMAIL_CONFIG_FIELDS.FROM_EMAIL_NAME,
    "Sender name should not be empty",
    subjectError.newErrors,
    inputsToValidate.from_email.name
  );

  let sentFromError = {
    validity: nameError.validity,
    newErrors: nameError.newErrors,
  };
  const shouldValidateFromEmail = inputsToValidate.from_email.email;

  if (inputData.sender_meta?.from_email === VALUE_META_TYPE.TEXT) {
    sentFromError = checkAndSetErrors(
      inputData.from_email.email,
      EMAIL_CONFIG_FIELDS.FROM_EMAIL,
      "Please enter a valid email address",
      nameError.newErrors,
      shouldValidateFromEmail,
      true
    );
  } else {
    sentFromError = checkAndSetErrors(
      inputData.from_email.email,
      EMAIL_CONFIG_FIELDS.FROM_EMAIL,
      "Send from should not be empty",
      nameError.newErrors,
      shouldValidateFromEmail
    );
  }

  let replyToError = {
    validity: sentFromError.validity,
    newErrors: sentFromError.newErrors,
  };
  const shouldValidateReplyToEmail = inputsToValidate.reply_to;

  if (inputData.sender_meta?.reply_to === VALUE_META_TYPE.TEXT) {
    replyToError = checkAndSetErrors(
      inputData.reply_to,
      EMAIL_CONFIG_FIELDS.REPLY_TO,
      "Please enter a valid email address",
      sentFromError.newErrors,
      shouldValidateReplyToEmail,
      true
    );
  } else {
    replyToError = checkAndSetErrors(
      inputData.reply_to,
      EMAIL_CONFIG_FIELDS.REPLY_TO,
      "Reply to should not be empty",
      sentFromError.newErrors,
      shouldValidateReplyToEmail
    );
  }

  let preheaderError = { ...replyToError };

  if ("preheader" in inputData && isString(inputData.preheader)) {
    preheaderError = checkAndSetErrors(
      inputData.preheader,
      EMAIL_CONFIG_FIELDS.PREHEADER,
      "Email preheader should not be empty",
      preheaderError.newErrors,
      inputsToValidate.preheader
    );
  }

  let ccBccErrors = {
    ...preheaderError,
  };

  const shouldValidateCcBcc =
    inputsToValidate.cc_email_data_set || inputsToValidate.bcc_email_data_set;

  if (
    shouldValidateCcBcc &&
    "cc_email_data_set" in inputData &&
    hasDuplicateRecipientInLists(
      inputData.bcc_email_data_set,
      inputData.cc_email_data_set
    )
  ) {
    ccBccErrors.validity = false;
    ccBccErrors.newErrors = {
      ...ccBccErrors.newErrors,
      cc_email_data_set: "Duplicates in Cc and Bcc fields",
      bcc_email_data_set: "Duplicates in Cc and Bcc fields",
    };
  }

  return {
    validity: [
      subjectError,
      preheaderError,
      nameError,
      sentFromError,
      replyToError,
      ccBccErrors,
    ].every((item) => item.validity),
    errors: ccBccErrors.newErrors,
  };
}

type AsyncThunkConfig = {
  getState: () => unknown;
  dispatch: Dispatch;
};

function apiCallValidation(actionName: string, props: any, state: RootState) {
  const {
    apiStatus: { apiStatus },
  } = state;
  if (actionName in apiStatus) {
    const propsHash = hash(props ?? {});
    if (propsHash in apiStatus[actionName]) {
      // Expiration check
      if ((apiStatus[actionName][propsHash].expiry as number) < Date.now())
        return true;
      // whether already called api check
      if (!isInit(apiStatus[actionName][propsHash].state)) return false;
    }
  }
  return true;
}

export function createAsyncThunkWrapper<U, T = void>({
  actionName,
  dispatchFn,
  apiCallRestrictionFn,
  isCachable = false,
}: {
  actionName: string;
  dispatchFn: (args: T, thunkApi: AsyncThunkConfig) => Promise<U>;
  apiCallRestrictionFn?: (args: T, getState: () => unknown) => boolean;
  isCachable?: boolean;
}) {
  return createAsyncThunk(
    actionName,
    async (args: T, { getState, dispatch }) => {
      // adding the api status to pending state/already called state
      isCachable &&
        dispatch(
          setApiStatus({
            actionName: actionName,
            props: args,
          })
        );

      return await dispatchFn(args, { getState, dispatch });
    },
    {
      condition: (args: T, { getState }) => {
        //custom condition to skip api call
        const customCondition = apiCallRestrictionFn
          ? apiCallRestrictionFn(args, getState)
          : true;
        //caching condition to skip api call
        const cacheValidation = isCachable
          ? apiCallValidation(actionName, args, getState() as RootState)
          : true;

        return (
          (args as unknown as { isForced: boolean })?.isForced ||
          (customCondition && cacheValidation)
        );
        //TODO: Hotfix for Forms V1. Remove unknown type casting and add flexibility to call dispatch forcibly even it's cached.
      },
    }
  );
}

export function getOperatorDetails(
  operator: string | null,
  operators: OperatorList | null,
  type?: OperatorType
) {
  return operator && operators && type
    ? operators[type][operator] ?? null
    : null;
}

export function validateLinksInTemplate(template: string): string {
  if (!template) {
    return "";
  }

  const parser = new DOMParser();
  const doc = parser.parseFromString(template, "text/html");
  const aTags = Array.from(doc.getElementsByTagName("a"));

  if (isEmpty(aTags)) {
    return "";
  }

  let invalidLinkMsg = "";

  aTags.some((aTag: HTMLAnchorElement) => {
    const hasHref = aTag.hasAttribute("href");
    if (!hasHref) return false;

    const url = aTag.getAttribute("href") ?? "";
    const checkForTokensAndHash =
      url.startsWith("{{") || url.startsWith("{%") || url === "#";

    const isInvalid = !checkForTokensAndHash && isEmptyProtocol(url);

    if (isInvalid)
      invalidLinkMsg = !url
        ? "Update all empty hyperLinks in the template, before saving it."
        : `The link ${url} is not in proper URL format.`;

    return isInvalid;
  });

  return invalidLinkMsg;
}

export function transformFiltersToParams(data?: { [key: string]: any }) {
  const params: { [key: string]: string } = {};
  Object.entries(data ?? {}).forEach(([key, value]) => {
    params[snakeCase(key)] = `${value}`;
  });
  return params;
}

export function formatTime({
  time,
  emptyContext = "",
}: {
  time: string;
  emptyContext?: string;
}) {
  if (isBlank(time)) return emptyContext;
  const [hourString, minute] = time.split(":");
  const hour = +hourString % 24;
  return (hour % 12 || 12) + ":" + minute + (hour < 12 ? " AM" : " PM");
}

export function getUpdatablePersonFields(
  personMappingDetails: PersonDestinationFields
): [string, PersonDestination][] {
  return Object.entries(personMappingDetails).filter(
    ([key, details]) => !details?.hidden && details?.updatable
  );
}

export function getPersonLabel(context: CAMPAIGN_CONTEXT) {
  return context === CAMPAIGN_CONTEXT.ORG ? "user" : "contact";
}

export function truncateTextAboveLimit(text: string, limit: number) {
  if (text.length <= limit) return text;
  return text.slice(0, limit) + "...";
}

export function redirectToContact(contactId: string) {
  if (contactId) {
    window.open(`${urls.person}/${contactId}`, "_blank");
  }
}

export function openInNewTab(url: string) {
  window.open(url, "_blank");
}

export function openAssetInNewTab(assetType: ASSET_TYPES, assetId: string) {
  const assetBaseUrl = ASSET_TYPES_INFO[assetType].url;
  const assetUrl = `${assetBaseUrl}/${assetId}`;
  openInNewTab(assetUrl);
}

export function initialisePaginationWithPageNo(pageSize: number) {
  return {
    ...INITIAL_PAGINATION,
    pageSize: pageSize,
  };
}

export function appendDateTime(name: string, date: string) {
  return name + " " + format(new Date(date), "yyyy-MM-dd-hh.mm.ss a");
}

export function downloadFileWithoutPageReload(url: string, fileName: string) {
  if (url && fileName) {
    try {
      var element = document.createElement("iframe");
      element.setAttribute("src", url);
      element.setAttribute("download", fileName);
      element.style.display = "none";
      document.body.appendChild(element);

      setTimeout(() => {
        document.body.removeChild(element);
      }, 1000);
    } catch (e) {
      throw new Error();
    }
  }
}

export function getFormattedTenantTimezone(tenantDetails: TenantDetails) {
  if (tenantDetails && tenantDetails.timezone) {
    return getUtcOffsetedTimezone(tenantDetails.timezone);
  } else {
    return EMPTY_CONTEXT;
  }
}
// helper function to get UTC offset for given timezone
export function getUtcOffset(timeZone: string) {
  try {
    const getAbsoluteValue = Math.abs;
    const date = new Date();
    const utcDate = new Date(date.toLocaleString("en-US", { timeZone: "UTC" }));
    const tzDate = new Date(date.toLocaleString("en-US", { timeZone }));

    const timeDifferenceInMinutes =
      (tzDate.getTime() - utcDate.getTime()) / 6e4;
    const hoursOffset = floor(timeDifferenceInMinutes / 60);
    const minutesOffset = getAbsoluteValue(timeDifferenceInMinutes) % 60;

    const utcOffsetString = `${hoursOffset < 0 ? "-" : "+"}${getAbsoluteValue(
      hoursOffset
    )
      .toString()
      .padStart(2, "0")}:${minutesOffset.toString().padStart(2, "0")}`;

    return utcOffsetString;
  } catch (error) {
    // If an error occurs (e.g., invalid timezone), return the empty string
    return "";
  }
}

// helper function to get UTC offseted timezone
export function getUtcOffsetedTimezone(timeZone: string) {
  const utcOffsetString = getUtcOffset(timeZone);
  return `UTC ${utcOffsetString} ${timeZone}`;
}

export async function uploadCsvFileToS3Url(file: File, url: string) {
  return await fetch(url, {
    method: "PUT",
    headers: {
      "Content-Type": "text/csv",
    },
    body: file,
  });
}

export function getTableRowLinkProps<T>(
  links: {
    to: string;
    editParam?: keyof T;
  },
  data: T
): (TableRowProps & LinkProps) | undefined {
  return links?.to
    ? {
        as: Link,
        to: `${links?.to}${
          links?.editParam ? "/" + data[links.editParam] : ""
        }`,
        target: "_blank",
        display: "table-row",
      }
    : undefined;
}

export function isUploadContactsToSl(uploadTo: UPLOAD_CONTACTS_TO) {
  return uploadTo === UPLOAD_CONTACTS_TO.STATIC_LIST;
}

export function isFailedExport(exportStatus: EXPORT_STATUS) {
  return [EXPORT_STATUS.FAILED, EXPORT_STATUS.ABORTED].includes(exportStatus);
}

export function isPendingExport(exportStatus: EXPORT_STATUS) {
  return [EXPORT_STATUS.QUEUED, EXPORT_STATUS.STARTED].includes(exportStatus);
}

// given an array of strings, return a string with commas and 'and' before the last element

export function addPunctuationSeparators(
  textArray: (string | number)[]
): string {
  const lastIndex = textArray.length - 1;

  const textArrayConcat = textArray.reduce((acc, current, index) => {
    if (index === 0) {
      return `${current}`;
    } else if (index === lastIndex) {
      return `${acc} and ${current}`;
    } else {
      return `${acc}, ${current}`;
    }
  }, "");

  return String(textArrayConcat);
}

export function getAbbreviatedTimezone(tzName: string | null) {
  return (
    ALL_TIMEZONES_WITH_ABBREVIATION.find(
      (timezone) => timezone.timezoneName === tzName
    )?.abbreviation ?? ""
  );
}

// To hide the resize observer error popup that blocks dev
export function hideResizeObserverError(e: ErrorEvent) {
  if (
    e.message === "ResizeObserver loop limit exceeded" ||
    e.message ===
      "ResizeObserver loop completed with undelivered notifications."
  ) {
    const resizeObserverErr = document.getElementById(
      "webpack-dev-server-client-overlay"
    );
    if (resizeObserverErr) {
      resizeObserverErr.setAttribute("style", "display: none");
    }
  }
}

export function getCustomDateRangeLabel(start: Date, end: Date) {
  return (
    Object.values(CUSTOM_DATE_RANGE_LABELS).find(
      (date: CUSTOM_DATE_RANGE_LABELS) =>
        isSameDay(new Date(), end) && isSameDay(getStartDate(date), start)
    ) ?? ""
  );
}

export function addOrRemoveArrayValue(
  list: (number | string)[] | undefined,
  newValue: number | string
) {
  if (list) {
    const copyList = cloneDeep(list);
    if (copyList.includes(newValue)) {
      return copyList.filter((item) => item !== newValue);
    } else {
      copyList.push(newValue);
      return copyList;
    }
  } else {
    return [newValue];
  }
}

export function searchInValue(value: string | undefined, searchText: string) {
  return !!value?.toLowerCase().includes(searchText.toLowerCase());
}

export function isStringToken(token: EmailTokenDetails) {
  return token.return_type === TOKEN_RETURN_TYPE.STRING;
}

export function isStringColumnAccessor(columnAccessor: EmailTokenColumn) {
  return columnAccessor.return_type === TOKEN_RETURN_TYPE.STRING;
}

export function addEmptyContextIfBlank(
  value: null | string | number | undefined
) {
  return isBlank(value) ? EMPTY_CONTEXT : value;
}

export function getTokenNameFromToken(tokenInput: string) {
  const tokenColReg =
    /^{{\s*(?:token|column)\s*\(\s*['"]([^'"]+)['"]\s*\)\s*}}$/;
  const matchedToken = tokenInput.match(tokenColReg);

  return matchedToken?.length === 2 ? matchedToken[1] : tokenInput;
}
export function transformTxtToToken(text: string) {
  return `{{ token("${text}") }}`;
}

export function transformTxtToColumnAccessor(text: string) {
  return `{{ column("${text}") }}`;
}

export function returnNullIfEmpty(value: any) {
  return isEmpty(value) ? null : value;
}

export function safeParseJSON<T>(jsonString: string): T | null {
  try {
    return JSON.parse(jsonString) as T;
  } catch (error) {
    return null;
  }
}

export function getTextWidth(text: string, fontSizeInPixels: number) {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  context && (context.font = fontSizeInPixels + "px Open-sans");
  const metrics = context?.measureText(text);

  return metrics?.width ?? 0;
}

export function isIngestionTypeFlink(type: CONTACTS_INGESTION_SERVICE | null) {
  return type === CONTACTS_INGESTION_SERVICE.FLINK;
}

export function initializePaginationWithLoadingState({
  pageSize,
}: {
  pageSize?: number;
}): PaginationTypeWithLoadingState<any> {
  return {
    loadingList: LOADING_STATES.INIT,
    list: null,
    pageSize: pageSize ?? 15,
    totalPageCount: null,
    currentPageNo: 1,
    changingPage: false,
    count: 0,
  };
}

export function getEpochTimeUtc(date: string | Date) {
  return new Date(date).getTime();
}

type StatusHandler = {
  onDone: (handler: () => Promise<void> | void) => StatusHandler;
  onPending: (handler: () => Promise<void> | void) => StatusHandler;
  onError: (handler: () => Promise<void> | void) => StatusHandler;
  onDefault: (handler: () => Promise<void> | void) => StatusHandler;
};

export function handleTransactionStatus(
  status?: TRANSACTION_STATUS
): StatusHandler {
  let isHandled = false; // Flag to ensure only one handler runs

  return {
    onDone(handler: () => void) {
      if (!isHandled && status === TRANSACTION_STATUS.DONE) {
        handler();
        isHandled = true;
      }
      return this;
    },
    onPending(handler: () => void) {
      if (!isHandled && status === TRANSACTION_STATUS.PENDING) {
        handler();
        isHandled = true;
      }
      return this;
    },
    onError(handler: () => void) {
      if (!isHandled && status === TRANSACTION_STATUS.ERROR) {
        handler();
        isHandled = true;
      }
      return this;
    },
    onDefault(handler: () => void) {
      if (!isHandled) {
        handler();
        isHandled = true;
      }
      return this;
    },
  };
}
